import {TranslateService} from '@ngx-translate/core';
import {Component, OnInit} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {ActivatedRoute, Router} from '@angular/router';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {getSlots, isVenueOpen, sleep, venueAcceptsOrders} from '../../../smoothr-web-app-core/utils/utils';
import {MenuPage} from '../menu/menu.page';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Address from '../../../smoothr-web-app-core/models/Address';
import {SelectTableModalComponent} from 'src/app/components/select-table-modal/select-table-modal.component';
import {ModalController} from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SignInPage} from '../sign-in/sign-in.page';
import {Api} from 'src/smoothr-web-app-core/api/api';
import {OrderType} from 'src/smoothr-web-app-core/enums/OrderType';
import {ScanQrModal} from 'src/app/components/scan-qr/scan-qr.component';
import { TimeUtils } from 'src/smoothr-web-app-core/utils/time-utils';

enum Language {
	GreatBritain = 'en',
	German = 'de'
}

@Component({
	selector: 'app-home',
	templateUrl: './home.page.breakburger.html',
	styleUrls: ['./home.page.breakburger.scss']
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	static scanUrl = 'scan-qr/:venueId/:tableNumber';
	static scanUrlTakeAway = 'scan-qr/:venueId/take_away';
	environment = environment;
	languageEnum = Language;

	loading = false;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY];
	activeVenue: Venue = null;

	constructor(
		protected repository: RepositoryService,
		private router: Router,
		public translate: TranslateService,
		private route: ActivatedRoute,
		private modalCtrl: ModalController,
		private snackBar: MatSnackBar
	) {
		super(repository);
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		await router.navigateByUrl(HomePage.url);
		if (repository) {
			repository.resetVenue();
			repository.address.emit(null);
			repository.order.emit(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
	}

	ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		this.locateAndShowStores();
		this.route.paramMap.subscribe(params => {
			console.log(params.get('tableNumber'), params.get('venueId'));
			if (params && params.get('venueId') && this.router.url.includes('takeaway')) {
				this.loadVenueAndTakeAway(params.get('venueId'));
				return;
			}
			if (params && params.get('tableNumber') && params.get('venueId')) {
				this.loadVenueByQrCode(params.get('tableNumber'), params.get('venueId'));
			}
		});
	}

	ionViewDidEnter() {
		if (history.state.navigationId === 1) {
			this.repository.order.emit(null);
		}
		this.repository.verifiedOrder.emit(null);
	}

	async locateAndShowStores() {
		this.loading = true;

		const venue = (await this.repository.getAllVenues()).find(ven => venueAcceptsOrders(ven, PreorderType.INSIDE) );

		if (!venue) {
			console.log('No available venues');
			this.loading = false;
			return;
		}
		this.activeVenue = venue;
		console.log(this.activeVenue);
		
	}
	async selectVenue(preorderType: PreorderType) {
		const isOpen = isVenueOpen(this.activeVenue);
		console.log('Restaurant opened:', isOpen);
		if (!isOpen) {
			this.snackBar.open(this.translate.instant('closed'), null, {
				duration: 5000
			});
			return;
		}
		await this.loadVenueAndCreateOrder(this.activeVenue, null, preorderType);
	}
	async loadVenueAndCreateOrder(venue: Venue, address: Address, preorderType: PreorderType) {
		console.log('loadVenueAndCreateOrder(', venue, address, preorderType, ')');
		const loadedVenue = await this.repository.getVenue(venue._id);
		this.repository.createOrder(loadedVenue, address, preorderType);
		await sleep(100);
		let selectTable = null;
		if (preorderType === PreorderType.INSIDE) {
			selectTable = await ScanQrModal.show(this.modalCtrl, this.venue);
			await sleep(150);
			console.log('selectTable', selectTable);
			if (selectTable && selectTable?.number) {
				this.order.tableNumber = selectTable.number;
				this.order.table = selectTable._id;
			} else {
				return;
			}
		}

		this.repository.order.emit(this.order);

		await MenuPage.navigate(this.router);
		this.loading = false;
	}

	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
	async loadVenueByQrCode(tableNumber: string, venueId: string) {
		this.loading = true;
		try {
			const venueData = await this.repository.getVenue(venueId);
			const table = (await Api.getTable(this.venue._id, tableNumber)).data;
			await sleep(100);
			const isOpenResturant = TimeUtils.doesHoursMatchNow(
				venueData?.openingHours
			);
			if (
				!isOpenResturant ||
				!venueData.isPublished ||
				!venueData.isServiceActivated
			) {
				this.loading = false;
				this.snackBar.open(
					this.translate.instant('home_page.venue_closed'),
					null,
					{
						duration: 5000
					}
				);
				return;
			}
			if (venueData && table) {
				console.log(table);
				const tableNumberUniqueLogic =
					table.number.toLocaleLowerCase() == '999';

				this.repository.venue.emit(venueData);
				this.repository.createOrder(
					this.venue,
					null,
					tableNumberUniqueLogic ? PreorderType.TAKE_AWAY : PreorderType.INSIDE
				);
				this.order.tableNumber = table.number;
				this.order.table = table._id;
				this.repository.order.emit(this.order);
				sleep(200);
				await MenuPage.navigate(this.router);
				// if (!tableNumberUniqueLogic) {
				// 	await SelectTableModalComponent.show(
				// 		this.modalCtrl,
				// 		venueData,
				// 		this.order
				// 	);
				// }
			}
			this.loading = false;
		} catch (e) {
			this.snackBar.open(
				this.translate.instant('home_page.scan_table_error'),
				null,
				{
					duration: 2000
				}
			);
			this.loading = false;
		}
	}

	async goToSignIn() {
		SignInPage.navigate(this.router);
		// just test logic for sending push notification
		// try {
		// 	const userDevice = JSON.parse(localStorage.getItem('deviceData'));
		// 	console.log(userDevice);
		// 	// alert(userDevice)
		// 	if (!userDevice) {
		// 		return;
		// 	}
		// 	const result = await Api.sendTestNotification({
		// 		device: userDevice._id,
		// 		content: 'Message',
		// 		subject: 'App'
		// 	});
		// 	// alert(result)
		// } catch (e) {
		// 	alert(e);
		// 	console.log(e);
		// }
	}

	async loadVenueAndTakeAway(venueId: string) {
		this.loading = true;		
		try {
			const venueData = await this.repository.getVenue(venueId);
			await sleep(100);
			if (venueData) {
				this.repository.venue.next(venueData);
				this.repository.createOrder(this.venue, null, PreorderType.TAKE_AWAY);
				sleep(200);
				await MenuPage.navigate(this.router);
			}
			this.loading = false;
		} catch (e) {
			this.snackBar.open(this.translate.instant('home_page.venue_not_found'), null, {
				duration: 2000
			});
			this.loading = false;
		}
	}
}
