import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {environment} from '../../../environments/environment';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { venueAcceptsOrders } from 'src/smoothr-web-app-core/utils/utils';
import { PreorderType } from 'src/smoothr-web-app-core/enums/PreorderType';
import { Api } from 'src/smoothr-web-app-core/api/api';

@Component({
	selector: 'app-impressum',
	templateUrl: './impressum.page.html',
	styleUrls: ['impressum.page.scss']
})
export class ImpressumPage {
	static url = 'impressum';
	environment = environment;
	html = '';
	loading = true;
	venue: Venue;

	constructor(private repository: RepositoryService, private router: Router) {}

	ngOnInit(): void {}
	ngOnDestroy(): void {}
	async ionViewDidEnter() {
		if (!this.venue) {
			this.venue = (await this.repository.getAllVenues()).find(ven => venueAcceptsOrders(ven, PreorderType.INSIDE));
		}

		if (this.venue && this.venue?.legal?.imprint) {
			this.html = this.venue?.legal?.imprint;
		} else {
			this.venue.legal = (await Api.getLegal(this.venue._id)).data;
			this.html = this.venue?.legal?.imprint || '';
		}
		this.loading = false;
	}

	static navigate(router: Router) {
		router.navigateByUrl(ImpressumPage.url);
	}

	goBack() {
		if (this.repository._order === null || !this.repository._venue) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
