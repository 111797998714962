import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {Api} from 'src/smoothr-web-app-core/api/api';
import {venueAcceptsOrders} from 'src/smoothr-web-app-core/utils/utils';
import {PreorderType} from 'src/smoothr-web-app-core/enums/PreorderType';
import Venue from 'src/smoothr-web-app-core/models/Venue';

@Component({
	selector: 'app-privacy',
	templateUrl: './privacy.page.html',
	styleUrls: ['privacy.page.scss']
})
export class PrivacyPage implements OnInit, OnDestroy {
	static url = 'privacy';
	checkIfVenueExistPrivacy = '';
	loading = true;
	venue: Venue;

	constructor(private repository: RepositoryService, private router: Router) {}

	ngOnInit(): void {}
	ngOnDestroy(): void {}
	async ionViewDidEnter() {
		if (!this.venue) {
			this.venue = (await this.repository.getAllVenues()).find(ven =>
				venueAcceptsOrders(ven, PreorderType.INSIDE)
			);
		}

		if (this.venue && this.venue?.legal?.privacy) {
			this.checkIfVenueExistPrivacy = this.venue?.legal?.privacy;
		} else {
			this.venue.legal = (await Api.getLegal(this.venue._id)).data;
			this.checkIfVenueExistPrivacy = this.venue?.legal?.privacy || '';
		}
		this.loading = false;
	}

	static async navigate(router: Router) {
		await router.navigateByUrl(PrivacyPage.url);
	}

	goBack() {

		if (this.repository._order === null || !this.repository._venue) {
			HomePage.navigate(this.router);
			return
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
