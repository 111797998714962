import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const breakBurgerCustomerGroup = 'break_burger';
export const customerGroup = breakBurgerCustomerGroup;

const supportEmail = {
	break_burger: 'break_burger@smoothr.de'
};
const firebaseConfig = {
	break_burger: {
		apiKey: 'AIzaSyAq-kQ8qbRRwK2MQwcVy96ysBD3_5QCHpQ',
		authDomain: 'break-burger-462c0.firebaseapp.com',
		projectId: 'break-burger-462c0',
		storageBucket: 'break-burger-462c0.appspot.com',
		messagingSenderId: '326021127852',
		appId: '1:326021127852:web:1000d11a4d0f79f60b4909'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyBxctZyYOPXBxP8pv-IsX0F3y16TB3nCFU',
	baseUrl: 'https://api.smoothr.de',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	allergens_link: 'https://hausmanns-restaurant.com/wp-content/uploads/2023/09/HM_Allergen-Uebersicht_FOOD23.pdf',
	hasRecommendations: false,
	...apiEnvironment,
	hjid: 5035140
};
